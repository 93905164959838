<template>
  <div
    class="bg-blue-200 absolute left-1/2 z-100 text-red-700 rounded-xl shadow-md flex transform -translate-x-1/2"
    :class="props.testMode ? 'top-14' : 'top-2'"
    v-if="impersonatedUser"
  >
    <div class="p-3 pr-1">
      Impersonating: <span class="font-bold">{{ impersonatedUser }}</span>
    </div>
    <lf-round-button class="pr-3 cursor-pointer" @click="stopImpersonate">
      <svg
        width="20"
        height="20"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_b)">
          <circle cx="22" cy="22" r="22" fill="rgba(0, 0, 255, 0.2)" />
        </g>
        <path
          d="M28.295 17.115C28.6844 16.7256 28.6844 16.0944 28.295 15.705C27.9056 15.3156 27.2744 15.3156 26.885 15.705L22 20.59L17.115 15.705C16.7256 15.3156 16.0944 15.3156 15.705 15.705C15.3156 16.0944 15.3156 16.7256 15.705 17.115L20.59 22L15.705 26.885C15.3156 27.2744 15.3156 27.9056 15.705 28.295C16.0944 28.6844 16.7256 28.6844 17.115 28.295L22 23.41L26.885 28.295C27.2744 28.6844 27.9056 28.6844 28.295 28.295C28.6844 27.9056 28.6844 27.2744 28.295 26.885L23.41 22L28.295 17.115Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_b"
            x="-4"
            y="-4"
            width="52"
            height="52"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImage" stdDeviation="2" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </lf-round-button>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  testMode: {
    type: Boolean,
    default: false
  }
});

const { getters, dispatch, commit } = useStore();

const impersonatedUser = computed(() => getters["auth/impersonating"]);

const stopImpersonate = async () => {
  await dispatch("auth/stopImpersonating");
  commit("resetHistoryStack", null, { root: true });
};
</script>
